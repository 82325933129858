<template>
  <div
    v-if="ramsNodeId"
  >
    <a-simple-datepicker
      :manual-doc="ramsDoc"
      field="operating_since"
      label="Operating since"
      @change="updateDaysInOperation"
    />
    <hr>
    <a-input
      v-for="value in decomposition"
      :key="value"
      :manual-doc="ramsDoc"
      :field="`decomposition.${value}`"
    >
      <template #label>
        <span>
          {{ value }}
        </span>
        <span>
          &nbsp; {{ $t(`failures.tools.decomposition.${value}`) }}
        </span>
      </template>
    </a-input>
  </div>
</template>


<script>
import moment from 'moment'
import { get } from 'lodash'
import ASimpleDatepicker from '@/components/Form/ASimpleDatepicker'

export default {
  components: {
    ASimpleDatepicker
  },
  data() {
    return {
      decomposition: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '06.01',
        '06.02',
        '07',
        '08'
      ]
    }
  },
  computed: {
    ramsNodeId() {
      return this.$store.state.route.query.ramsNode
    },
    ramsDoc() {
      return {
        module: 'ramsNodes',
        id: this.ramsNodeId
      }
    },
    ramsDocData() {
      return this.$store.getters["ramsNodes/data"][this.ramsNodeId]
    }
  },
  async created() {
    if(this.ramsNodeId) {
      await this.$store.dispatch('ramsNodes/fetchById', this.ramsNodeId)
    }
  },
  methods: {
    updateDaysInOperation() {
      const reported_at = get(this.ramsDocData, 'failure.progress.reported.at')
      const operating_since = get(this.ramsDocData, 'operating_since')
      if(reported_at && operating_since) {
        const daysInOperation = moment(reported_at).diff(moment(operating_since), 'days')
        console.log('daysInOperation', daysInOperation)
        this.$store.dispatch('ramsNodes/set', {
          id: this.ramsNodeId,
          daysInOperation
        })
      }
    }
  }
}
</script>
